.excel-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .excel-table th,
  .excel-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .excel-table th {
    background-color: #f2f2f2;
  }
  
  .excel-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .excel-table tbody tr:hover {
    background-color: #e5e5e5;
  }


  .table-list-container {
    overflow-y: auto;
    padding: 1rem;
    width: 120%;
  }
  
  .table-list {
    display: flex;
    flex-wrap: nowrap;
    /* Set a fixed width or max-width for the .table-list */
    width: 100%; 
    overflow-x: auto; /* Hide any content that goes beyond the .table-list width */
  }
  
  .table-item {
    flex: 0 0 auto;
    margin-top: 60px;
    margin-right: 1rem;
    min-width: 200px;
    margin-top: 60px
    /* Additional styles for table item */
  }
  
  @media screen and (max-width: 600px) {
    .table-item {
      min-width: 150px;
      /* Additional styles for smaller screens */
    }
  }
  