.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}


.card-stats {
    .card-body {
        padding: 1rem 1.5rem;
    }

    .card-status-bullet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}


.card-profile-image {
    position: relative;

    img {
        max-width: 180px;
        border-radius: .25rem;
        // @extend .shadow;
        transform: translate(-50%,-30%);
        position: absolute;
        left: 50%;
        transition: all .15s ease;

        &:hover {
            transform: translate(-50%, -33%);
        }
    }
}

.card-profile-stats {
    padding: 1rem 0;

    > div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }
        .description {
            font-size: .875rem;
            color: #adb5bd;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}

.card-lift--hover {
    &:hover {
        transform: translateY(-20px);
        // @include transition($all .15s ease);
    }
}

// @import "./custom_variable";